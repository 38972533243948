import languageInterceptor from './request/language.interceptor';
import tokenInterceptor from './request/token.interceptor';
import unauthenticated from './response/unauthenticated.interceptor';

// Request interceptors
const requestInterceptors = [tokenInterceptor, languageInterceptor];

// Response interceptors
const responseInterceptors = [unauthenticated];

export { requestInterceptors, responseInterceptors };
