import { AxiosRequestConfig } from 'axios';

import store from 'store';
import { getLocale } from 'store/intl/intl.selectors';

const success = (connection: AxiosRequestConfig) => {
  const locale = getLocale(store.store.getState());

  if (connection.headers && locale) {
    connection.headers['Accept-Language'] = locale.toUpperCase();
  }

  return connection;
};

const error = (connection: AxiosRequestConfig) => Promise.reject(connection);

export default { success, error };
