import { AxiosError, AxiosResponse } from 'axios';

import store from 'store';
import { authActions } from 'store/auth/auth.slice';

const success = (response: AxiosResponse) => response;

const error = (connection: AxiosError) => {
  if (connection.response?.status === 401) {
    store.store.dispatch(authActions.RESET());
  }

  return Promise.reject(connection);
};

export default { success, error };
