import _shuffle from 'lodash/shuffle';
import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { QueryKeys } from 'queries';
import { StaleTime } from 'queries/constants';
import { apiService } from 'services';
import { queryClient } from 'services/react-query';

const fetchVotingState = async () => {
  const { data: response } = await apiService.getVotingState();
  return response;
};

export const prefetchVotingState = async () => {
  try {
    await queryClient.prefetchQuery(QueryKeys.voting.state(), () =>
      fetchVotingState(),
    );
  } catch {}
};

export const useVotingState = () => {
  const { data, status, isLoading, error } = useQuery(
    QueryKeys.voting.state(),
    () => fetchVotingState(),
    {
      refetchOnReconnect: true,
      refetchIntervalInBackground: true,
      refetchInterval: 10000,
      cacheTime: StaleTime.ONE_HOUR,
      staleTime: StaleTime.MINUTE,
    },
  );

  const randomOrderData = useMemo(() => {
    if (data) {
      return {
        ...data,
        participants: _shuffle(data.participants),
      };
    }
  }, [data]);

  return {
    voting: randomOrderData,
    status,
    isLoading,
    error,
  };
};
