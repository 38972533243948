import { useQuery } from 'react-query';

import { useAuth } from 'hooks/useAuth';
import { QueryKeys } from 'queries';
import { StaleTime } from 'queries/constants';
import { apiService } from 'services';

const fetchVote = async () => {
  const { data: response } = await apiService.getVote();
  return response;
};

export const useVote = () => {
  const { isLoggedIn } = useAuth();
  const { data, status, isLoading, error } = useQuery(
    QueryKeys.voting.user(),
    () => fetchVote(),
    {
      enabled: isLoggedIn,
      retry: false,
      retryOnMount: false,
      cacheTime: StaleTime.FOREVER,
      staleTime: StaleTime.FOREVER,
    },
  );

  return {
    vote: data,
    status,
    isLoading,
    error,
  };
};
