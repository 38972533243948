import { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import { useMutation, useQueryClient } from 'react-query';

import { useIntl } from 'hooks';
import { QueryKeys } from 'queries';
import { apiService } from 'services';
import { Candidate, Vote } from 'types';

const postVote = async (candidate: Candidate) => {
  const { data: response } = await apiService.postVote(candidate);
  return response;
};

export const usePostVote = () => {
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { mutate, data, isLoading, error } = useMutation(
    (candidate: Candidate) => postVote(candidate),
    {
      onSuccess: async (data) => {
        const votingQueryKey = QueryKeys.voting.user();
        await queryClient.cancelQueries(votingQueryKey);
        queryClient.setQueryData<Vote>(votingQueryKey, () => {
          return { ...data };
        });
      },
      onError: (error: AxiosError) => {
        if (error.response?.status === 400) {
          const message = error.response?.data['errors'];
          if (message === 'User already voted') {
            queryClient.invalidateQueries(QueryKeys.voting.user());
            enqueueSnackbar(
              intl.formatMessage({ id: 'vote.toast.already_voted.error' }),
              {
                variant: 'error',
              },
            );
          } else if (message === 'Votes are closed') {
            enqueueSnackbar(
              intl.formatMessage({ id: 'vote.toast.voting_closed.error' }),
              {
                variant: 'error',
              },
            );
          } else {
            showErrorToast();
          }
        } else {
          showErrorToast();
        }
      },
    },
  );

  const showErrorToast = () => {
    enqueueSnackbar(
      intl.formatMessage(
        { id: 'vote.toast.error' },
        {
          sms: (
            <a
              target="_blank"
              href="https://vtm.be/huis-gemaakt/wedstrijdreglement"
              rel="noreferrer"
              style={{ color: 'white', marginLeft: 4 }}
            >
              sms
            </a>
          ),
        },
      ),
      {
        variant: 'error',
      },
    );
  };

  return {
    postVote: mutate,
    guess: data,
    isLoading,
    error,
  };
};
