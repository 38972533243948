import { createTheme } from '@mui/material/styles';

/**
 *   Setup base theme allows to be imported at other places (ex, palette, typography, spacing,...)
 */
let theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 768,
      md: 1040,
      lg: 1440,
      xl: 1700,
    },
  },
  palette: {
    background: {
      default: '#ffffff',
    },
    primary: {
      main: '#ffffff',
    },
    secondary: {
      main: '#6666a3',
    },
    text: {
      primary: '#ffffff',
    },
  },
  typography: {
    fontSize: 16,
    fontFamily: '"Nunito", sans-serif',
    h1: {
      fontWeight: 800,
      fontSize: 56,
    },
    h2: {
      fontWeight: 'bold',
      fontSize: '48px',
      lineHeight: 1,
    },
    h3: {
      fontSize: 32,
      lineHeight: '32px',
      fontWeight: 900,
    },
    h4: {
      fontWeight: 'bold',
      fontSize: '1.5rem',
      lineHeight: 0.9,
    },
    h5: {
      fontWeight: 'bold',
      fontSize: '1.25rem',
      lineHeight: 1.3,
    },
    h6: {
      fontWeight: 'bold',
      fontSize: '1.125rem',
      lineHeight: 1.3,
    },
    body1: {
      fontWeight: 400,
      fontSize: '18px',
      lineHeight: '24px',
    },
    body2: {
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: '22px',
    },
  },
});

theme.typography.h1 = {
  ...theme.typography.h1,
  [theme.breakpoints.down('sm')]: {
    fontSize: 32,
  },
};

theme.typography.h2 = {
  ...theme.typography.h2,
  [theme.breakpoints.down('md')]: {
    fontSize: 16,
  },
};

theme.typography.h3 = {
  ...theme.typography.h3,
  [theme.breakpoints.down('md')]: {
    fontSize: 16,
    lineHeight: '20px',
  },
};

theme.typography.body1 = {
  ...theme.typography.body1,
  [theme.breakpoints.down('sm')]: {
    fontSize: 16,
    lineHeight: '20px',
  },
};

export const baseTheme = theme;
