import React, { createContext, useContext } from 'react';
import { IntlShape, useIntl as useReactIntl } from 'react-intl';
import { injectIntl, ReactIntlPhraseProps } from 'react-intl-phraseapp';

import config from 'config';

const IntlContext = createContext({});

const SetIntlContext: React.ComponentType<
  ReactIntlPhraseProps & { enabled?: boolean }
> = ({ formatMessage, translate, children, enabled }) => {
  const intl = useReactIntl();
  return React.createElement(
    IntlContext.Provider,
    { value: { ...intl, ...(enabled ? { formatMessage, translate } : {}) } },
    children,
  );
};

export const InjectIntlContext = injectIntl(SetIntlContext);

export const useIntl = config.PHRASE_DEV
  ? () => useContext(IntlContext) as IntlShape
  : useReactIntl;
