import { AxiosError } from 'axios';
import { all, call, put, takeLatest } from 'redux-saga/effects';

import { prefetchVotingState } from 'queries';
import { checkForLogin, validateToken } from 'store/auth/auth.saga';

import { systemActions } from './system.slice';

function* initApp() {
  try {
    /**
     * start your saga flows
     */
    yield call(validateToken);
    yield call(checkForLogin);

    yield call(prefetchVotingState);
    /**
     * system is ready -> time to render UI!
     */
    yield put(systemActions.SYSTEM_READY());
  } catch (e: unknown) {
    yield put(systemActions.SYSTEM_FAIL(e as AxiosError<unknown>));
  }
}

export default function* () {
  yield all([takeLatest(systemActions.SYSTEM_START, initApp)]);
}
