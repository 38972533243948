import { Candidate, Vote, Voting } from 'types';

import RestApiService from './';

export default {
  getVotingState(this: RestApiService) {
    return this.api.get<Voting>('/voting-state');
  },
  postVote(this: RestApiService, candidate: Candidate) {
    return this.api.post<Vote>(
      '/vote',
      { participantsId: candidate },
      { authEnabled: true },
    );
  },
  getVote(this: RestApiService) {
    return this.api.get<Vote>('/vote', { authEnabled: true });
  },
};
