import * as React from 'react';
import { memo, SVGProps } from 'react';

const SvgIcoPhraseapp = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 32 32" width="1em" height="1em" {...props}>
    <defs>
      <linearGradient
        id="a"
        x1={18.968}
        y1={19.819}
        x2={21.935}
        y2={18.739}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.33} stopColor="#f60" />
        <stop offset={1} stopColor="#ffb800" />
      </linearGradient>
      <linearGradient
        id="b"
        x1={18.996}
        y1={13.493}
        x2={30.06}
        y2={12.525}
        xlinkHref="#a"
      />
      <linearGradient
        id="c"
        x1={13.867}
        y1={16.752}
        x2={25.287}
        y2={6.103}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#e7e7e7" />
        <stop offset={0.024} stopColor="#eaeaea" />
        <stop offset={0.178} stopColor="#f5f5f5" />
        <stop offset={0.396} stopColor="#fcfcfc" />
        <stop offset={1} stopColor="#fefefe" />
      </linearGradient>
      <linearGradient
        id="d"
        x1={16.345}
        y1={29.317}
        x2={17.597}
        y2={5.432}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#05588c" />
        <stop offset={1} stopColor="#3db5ff" />
      </linearGradient>
      <linearGradient
        id="e"
        x1={8.012}
        y1={25.566}
        x2={21.637}
        y2={1.967}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.4} stopColor="#005082" />
        <stop offset={1} stopColor="#2d9bdf" />
      </linearGradient>
    </defs>
    <title>{'file_type_phraseapp'}</title>
    <path d="M19.455,6.767a2.241,2.241,0,1,1-1.16.789A2.248,2.248,0,0,1,19.455,6.767Zm1.036.551a.787.787,0,1,0,.943.491A.786.786,0,0,0,20.491,7.318Z" />
    <path
      d="M22.462,20.323a2.109,2.109,0,0,0-.327-.87,5.962,5.962,0,0,0-.721-.906c-.186-.218-.407-.4-.593-.623-.148-.183-.334-.332-.486-.512-.115.252-.229.5-.342.757l-.807,1.789c-.03.068-.062.136-.091.2a7.258,7.258,0,0,0,1.884.368,7.073,7.073,0,0,0,.865,0c.178-.009.356-.026.534-.049a.591.591,0,0,0,.081-.027A.312.312,0,0,0,22.462,20.323Z"
      style={{
        fill: 'url(#a)',
      }}
    />
    <path
      d="M28.15,6.631A9.4,9.4,0,0,0,26.07,4.586c-.018.04-.036.08-.053.12-.173.387-.345.774-.518,1.161-.046.1-.093.208-.139.312s-.088.2-.131.295q-.38.852-.76,1.7-.327.734-.654,1.468l-.449,1.007q-.182.412-.366.823a2.005,2.005,0,0,1-.727.875A4.464,4.464,0,0,1,21,12.829a2.827,2.827,0,0,0-1.1.516,2.324,2.324,0,0,0-.733,1.36,2.272,2.272,0,0,0,.17,1.277,5.384,5.384,0,0,0,.995,1.431c.152.18.337.329.486.512.186.218.406.4.593.623a5.962,5.962,0,0,1,.721.906,2.109,2.109,0,0,1,.327.87.312.312,0,0,1,0,.133.591.591,0,0,1-.081.027l.091-.011a1.753,1.753,0,0,1-.364,1.035,9.418,9.418,0,0,0,2.464-.755h0c.238-.108.469-.231.7-.358v0c.294-.16.57-.352.846-.539v-.014a.582.582,0,0,0,.123-.082A9.446,9.446,0,0,0,27.855,18.2,9.418,9.418,0,0,0,29.9,13.592,9.423,9.423,0,0,0,28.15,6.631Z"
      style={{
        fill: 'url(#b)',
      }}
    />
    <path
      d="M24.52,5.672A7.788,7.788,0,0,0,21,4.729a7.618,7.618,0,0,0-2.116.24q-.327.085-.644.2c-.212.077-.42.167-.625.26A7.859,7.859,0,0,0,16.04,6.458a8.292,8.292,0,0,0-1.1,1.117,8.653,8.653,0,0,0-.635.888c-.113.177-.216.361-.316.546a9.013,9.013,0,0,0-.437.963q-.139.368-.245.747a7.773,7.773,0,0,0-.255,1.4c0,.043-.008.087-.012.131a6.762,6.762,0,0,0,.125,1.872,9.706,9.706,0,0,0,.445,1.558,7.2,7.2,0,0,0,1.379,2.273A6.681,6.681,0,0,0,16.6,19.24a8.4,8.4,0,0,0,2.5.922c.03-.068.061-.136.091-.2l.807-1.789c.114-.253.227-.505.342-.757a5.384,5.384,0,0,1-.995-1.431,2.272,2.272,0,0,1-.17-1.277,2.324,2.324,0,0,1,.733-1.36,2.827,2.827,0,0,1,1.1-.516,4.464,4.464,0,0,0,1.272-.478A2.005,2.005,0,0,0,23,11.476q.183-.411.366-.823l.449-1.007q.326-.735.654-1.468.379-.852.76-1.7c.043-.1.088-.2.131-.295C25.079,6.011,24.809,5.825,24.52,5.672Zm-3.073,5.011A2.237,2.237,0,1,1,22.276,9.3,2.24,2.24,0,0,1,21.447,10.683Z"
      style={{
        fill: 'url(#c)',
      }}
    />
    <path
      d="M23.636,29.54q-.631-.519-1.212-1.094h0a13.578,13.578,0,0,1-2.575-3.465l-.019,0,.018,0a9.02,9.02,0,0,1-.745-2.015,6.46,6.46,0,0,1-.17-1.793,4.814,4.814,0,0,1,.162-1.007,8.4,8.4,0,0,1-2.5-.922,6.681,6.681,0,0,1-1.611-1.289,7.2,7.2,0,0,1-1.379-2.273,9.706,9.706,0,0,1-.445-1.558,6.762,6.762,0,0,1-.125-1.872c0-.044.007-.088.012-.131a7.774,7.774,0,0,1,.255-1.4q.107-.378.245-.747a9.013,9.013,0,0,1,.437-.963c.1-.185.2-.369.316-.546a8.653,8.653,0,0,1,.635-.888,8.292,8.292,0,0,1,1.1-1.117,7.859,7.859,0,0,1,1.578-1.025,2.9,2.9,0,0,0-.463.16,14.011,14.011,0,0,0-1.926.953,12.41,12.41,0,0,0-1.037.694c-.25.18-.487.379-.722.579-.247.215-.483.441-.711.676-.173.17-.331.355-.492.537-.093.107-.182.217-.27.328-.164.2-.314.413-.462.626a11.271,11.271,0,0,0-.617,1.009c-.065.119-.126.241-.187.362-.133.26-.248.53-.358.8-.081.2-.15.4-.22.6-.019.055-.038.109-.056.164a11.221,11.221,0,0,0-.323,1.247c-.044.208-.077.417-.11.627q-.076.522-.1,1.049c-.02.258-.016.517-.023.775.014.3.019.592.047.887a12.423,12.423,0,0,0,.985,3.876,10.239,10.239,0,0,0,.616,1.235,12.73,12.73,0,0,0,1.444,2.026c.182.251.434.439.623.684.071.084.166.141.243.218.231.24.492.448.744.666a14.316,14.316,0,0,0,2.781,1.777,17.28,17.28,0,0,0,3.184,1.158,19.536,19.536,0,0,0,2.953.5,5.535,5.535,0,0,1,.588.064l.068-.008A1.378,1.378,0,0,0,23.636,29.54Z"
      style={{
        fill: 'url(#d)',
      }}
    />
    <path
      d="M20.941,2.342v0a14.35,14.35,0,0,0-3.335-.328,13.966,13.966,0,0,0-2.24.217c-.666.122-1.327.276-1.982.449-.773.2-1.539.428-2.3.662-.806.246-1.61.5-2.416.747-.683.2-1.369.4-2.062.574a14.452,14.452,0,0,1-2,.36A5.927,5.927,0,0,1,3.141,5,2.983,2.983,0,0,1,2,4.584,6.854,6.854,0,0,0,3.254,7.494h0a6.864,6.864,0,0,0,2.065,1.84,14.842,14.842,0,0,0-.624,1.6,14.254,14.254,0,0,0-.383,1.419l0,.025-.007-.01a13.473,13.473,0,0,0-.29,2.991,13.643,13.643,0,0,0,1.906,6.684,15.409,15.409,0,0,0,3.537,4.111,16.888,16.888,0,0,0,6.345,3.231,18.478,18.478,0,0,0,7.938.319,5.534,5.534,0,0,0-.588-.064,19.536,19.536,0,0,1-2.953-.5,17.28,17.28,0,0,1-3.184-1.158,14.316,14.316,0,0,1-2.781-1.777c-.252-.218-.513-.426-.744-.666-.078-.077-.173-.134-.243-.218-.189-.245-.441-.433-.623-.684a12.73,12.73,0,0,1-1.444-2.026,10.239,10.239,0,0,1-.616-1.235A12.423,12.423,0,0,1,9.587,17.5c-.028-.295-.034-.591-.047-.887.007-.258,0-.517.023-.775q.026-.527.1-1.049c.033-.209.065-.419.11-.627a11.221,11.221,0,0,1,.323-1.247c.018-.055.037-.11.056-.164.07-.2.138-.4.22-.6.111-.271.225-.54.358-.8.061-.122.122-.243.187-.362a11.271,11.271,0,0,1,.617-1.009c.148-.213.3-.425.462-.626.088-.111.177-.221.27-.328.161-.182.319-.367.492-.537.228-.234.465-.461.711-.676.236-.2.472-.4.722-.579a12.41,12.41,0,0,1,1.037-.694,14.011,14.011,0,0,1,1.926-.953,2.9,2.9,0,0,1,.463-.16c.205-.094.413-.183.625-.26s.426-.146.644-.2A7.618,7.618,0,0,1,21,4.729a7.788,7.788,0,0,1,3.517.943c.289.153.559.339.839.508.046-.1.093-.208.139-.312.173-.387.345-.774.518-1.161.017-.04.035-.08.053-.12A14.306,14.306,0,0,0,20.941,2.342Z"
      style={{
        fill: 'url(#e)',
      }}
    />
  </svg>
);

const Memo = memo(SvgIcoPhraseapp);
export default Memo;
