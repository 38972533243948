import { Components } from '@mui/material';

export const MuiContainer: Components['MuiContainer'] = {
  defaultProps: { maxWidth: 'lg' },
  styleOverrides: {
    root: {
      position: 'relative',
    },
  },
};
