import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  getAuthLoading,
  getUserId,
  isStoredTokenValid,
} from 'store/auth/auth.selectors';
import { authActions } from 'store/auth/auth.slice';

export const useAuth = () => {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(isStoredTokenValid);
  const userId = useSelector(getUserId);
  const isLoading = useSelector(getAuthLoading);

  const login = useCallback(
    (redirectRoute?: string) => {
      dispatch(authActions.LOGIN_START(redirectRoute));
    },
    [dispatch],
  );

  return { isLoggedIn, login, isLoading, userId };
};
