import { AxiosInstance } from 'axios';

import authApi from './auth.api';
import votingApi from './voting.api';

interface Options {
  api: AxiosInstance;
}

class RestApiService {
  public api: AxiosInstance;

  constructor(options: Options) {
    this.api = options.api;
  }

  // AUTH
  public getAuth = authApi.getAuth;

  // VOTING
  public getVotingState = votingApi.getVotingState;
  public postVote = votingApi.postVote;
  public getVote = votingApi.getVote;
}

export default RestApiService;
