import { Components } from '@mui/material';

export const MuiChip: Components['MuiChip'] = {
  styleOverrides: {
    root: {
      borderRadius: 4,
    },
    label: {
      fontWeight: 800,
      fontSize: 15,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
};
