import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface AuthState {
  loading: boolean;
  accessToken?: string;
  redirectRoute?: string;
}

const initialState: AuthState = {
  loading: false,
  accessToken: undefined,
  redirectRoute: undefined,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    RESET: (state) => {
      state.accessToken = undefined;
    },
    SET_ACCESS_TOKEN: (
      state,
      { payload }: PayloadAction<AuthState['accessToken']>,
    ) => {
      state.accessToken = payload;
    },
    LOGIN_START: (
      state,
      { payload }: PayloadAction<AuthState['redirectRoute']>,
    ) => {
      state.loading = true;
      state.redirectRoute = payload;
    },
    LOGIN_LOADING: (state) => {
      state.loading = true;
    },
    LOGIN_SUCCESS: (state) => {
      state.loading = false;
      state.redirectRoute = undefined;
    },
    LOGIN_ERROR: (state) => {
      state.loading = false;
      state.redirectRoute = undefined;
    },
  },
});

export const { actions: authActions } = authSlice;

export default authSlice.reducer;
