enum Keys {
  Voting = 'voting',
}

export const QueryKeys = {
  voting: {
    state: () => [Keys.Voting, 'state'],
    user: () => [Keys.Voting, 'user'],
  },
};
