import { FC } from 'react';
import { Redirect, Route } from 'react-router-dom';

import { Routes } from 'pages/routes.constants';
import { Route as RouteProps } from 'pages/routes.types';

interface Props extends RouteProps {
  user?: unknown;
}

const RouteProtected: FC<Props> = ({ path, user, ...rest }) => {
  const isAuthorized = !!user;
  return isAuthorized ? (
    <Route {...rest} />
  ) : (
    <Redirect to={Routes.RedirectNotAuthorized} />
  );
};

export default RouteProtected;
