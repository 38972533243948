import * as Sentry from '@sentry/browser';
import axios from 'axios';
import qs from 'qs';
import {
  all,
  call,
  put,
  SagaReturnType,
  select,
  takeLatest,
} from 'redux-saga/effects';

import config from 'config';

import { isTokenValid } from 'utils/token.utils';
import { inIframe } from 'utils/window.utils';

import { apiService, historyService } from 'services';

import { Routes } from 'pages/routes.constants';

import { getRedirectRoute, isStoredTokenValid } from './auth.selectors';
import { authActions } from './auth.slice';

export function* validateToken() {
  const isValid: SagaReturnType<typeof isStoredTokenValid> = yield select(
    isStoredTokenValid,
  );
  if (!isValid) {
    yield put(authActions.RESET());
  }
}

export function* checkForLogin() {
  const isValid: SagaReturnType<typeof isStoredTokenValid> = yield select(
    isStoredTokenValid,
  );

  if (isValid) {
    // If the token we have is valid... Why do we want to login again
    // This check could fix an refresh problem with the same code everytime
    return;
  }

  const { code } = qs.parse(window.location.search, {
    ignoreQueryPrefix: true,
  });

  if (
    code &&
    typeof code === 'string' &&
    window.location.pathname === Routes.Root
  ) {
    try {
      const redirectUri = inIframe()
        ? config.IFRAME_CLIENT_URL
        : config.CLIENT_URL;

      yield put(authActions.LOGIN_LOADING());

      const { data: authData }: SagaReturnType<typeof apiService.getAuth> =
        yield call([apiService, apiService.getAuth], code, redirectUri);

      const isValid = isTokenValid(authData.accessToken);

      if (isValid) {
        const redirectRoute: SagaReturnType<typeof getRedirectRoute> =
          yield select(getRedirectRoute);

        yield put(authActions.SET_ACCESS_TOKEN(authData.accessToken));
        yield put(authActions.LOGIN_SUCCESS());

        if (redirectRoute) {
          historyService.replace(redirectRoute);
        }
      } else {
        throw new Error('Token not valid');
      }
    } catch (e) {
      yield put(authActions.LOGIN_ERROR());

      if (axios.isAxiosError(e)) {
        const errorMessage = e.response?.data?.error;
        Sentry.withScope((scope) => {
          scope.setExtras({
            name: errorMessage ?? 'Login error',
            error: e,
          });
          Sentry.captureMessage(errorMessage ?? 'Login error');
        });
      } else {
        Sentry.withScope((scope) => {
          scope.setExtras({
            name: 'login-error',
            error: e,
          });
          Sentry.captureMessage('Login error');
        });

        historyService.replace(`${Routes.LoginCallback}?error=Login error`);
      }
    }
  }
}

function loginStart() {
  if (window.top && inIframe()) {
    window.top.location.href = `https://login2.vtm.be/authorize?client_id=${config.VTM_CLIENT_ID}&response_type=code&redirect_uri=${config.IFRAME_CLIENT_URL}&scope=openid email profile`;
  } else {
    window.location.href = `https://login2.vtm.be/authorize?client_id=${config.VTM_CLIENT_ID}&response_type=code&redirect_uri=${config.CLIENT_URL}&scope=openid email profile`;
  }
}

export default function* () {
  yield all([takeLatest(authActions.LOGIN_START, loginStart)]);
}
