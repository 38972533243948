import { IconButton, styled } from '@mui/material';
import React from 'react';
import { initializePhraseAppEditor } from 'react-intl-phraseapp';

import config from 'config';

import { InjectIntlContext } from 'hooks';

import { IcoPhraseapp } from 'components/@icons';

const PhraseButton = styled(IconButton)`
  position: fixed;
  z-index: 9999;
  top: 24px;
  left: 24px;
  opacity: 1;
`;

const handlePhraseClick = () => {
  if (config.PHRASE_DEV) {
    let config = {
      projectId: '5a655698221e3d6b20733732afe7b667',
      phraseEnabled: true,
      prefix: '[[__',
      suffix: '__]]',
      fullReparse: true,
    };

    initializePhraseAppEditor(config);
  }
};

interface State {
  phraseEnabled?: boolean;
}

class PhraseProvider extends React.Component<{}, State> {
  override state = { phraseEnabled: false };

  override shouldComponentUpdate(_nextProps: {}, nextState: State) {
    return this.state.phraseEnabled !== nextState.phraseEnabled;
  }

  public override render() {
    const { children } = this.props;

    return (
      <>
        {config.PHRASE_DEV ? (
          // @ts-ignore
          <InjectIntlContext enabled={this.state.phraseEnabled}>
            <>
              {!this.state.phraseEnabled ? (
                <>
                  {children}
                  <PhraseButton
                    onClick={() => {
                      this.setState({ phraseEnabled: true });
                      handlePhraseClick();
                    }}
                  >
                    <IcoPhraseapp />
                  </PhraseButton>
                </>
              ) : (
                children
              )}
            </>
          </InjectIntlContext>
        ) : (
          children
        )}
      </>
    );
  }
}

export default PhraseProvider;
