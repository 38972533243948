import { AxiosRequestConfig } from 'axios';

import { isDev } from 'utils/env.utils';

import store from 'store';
import { getAccessToken } from 'store/auth/auth.selectors';

const success = (connection: AxiosRequestConfig) => {
  const token = getAccessToken(store.store.getState());

  if (connection.headers && connection.authEnabled) {
    if (token) {
      connection.headers['Authorization'] = `Bearer ${token}`;
    } else if (isDev()) {
      connection.headers['X-FAKE-USER-ID'] =
        'cb6dab51-8769-4a49-8505-7b224c871872';
    }
  }

  return connection;
};

const error = (connection: AxiosRequestConfig) => Promise.reject(connection);

export default { success, error };
