import { combineReducers } from 'redux';

import authReducer from './auth/auth.slice';
import intlReducer from './intl/intl.slice';
import systemReducer from './system/system.slice';

const reducers = {
  system: systemReducer,
  intl: intlReducer,
  auth: authReducer,
};

export const rootReducer = combineReducers(reducers);

export type RootReducer = typeof rootReducer;
export type Store = ReturnType<RootReducer>;

export default rootReducer;
