import { Components } from '@mui/material';

export const MuiButton: Components['MuiButton'] = {
  styleOverrides: {
    containedPrimary: {
      padding: '10px 16px',
      backgroundColor: '#000000',
      background: 'linear-gradient(92.12deg, #D25000 2.33%, #FF6E00 97.57%)',
      '&:hover': {
        background: 'linear-gradient(92.12deg, #D25000 2.33%, #FF5F00 97.57%)',
      },
      '&:disabled': {
        background: '#D2D2D2',
      },
    },
    containedSecondary: {
      backgroundColor: '#000000',
      fontSize: '14px',
      height: 40,
      padding: '10px 16px',
      '&:hover': {
        backgroundColor: 'rgb(32, 32, 32)',
      },
    },
    root: {
      borderRadius: '60px',
      fontFamily: 'Nunito',
      fontWeight: 900,
      fontSize: '18px',
      padding: '10px 24px',
      textTransform: 'none',
      color: '#ffffff',
      ':focus-visible': {
        outline: '1px solid white',
      },
    },
  },
};
